import { useEffect } from "react";
import { Link } from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import {generoCreacionDTO} from "./generos.model";


export default function IndexGeneros(){
    useEffect(() => {
        axios.get('https://api.solverpro.com/api/generos')
        .then((respuesta: AxiosResponse<generoCreacionDTO[]>) => {
            console.log(respuesta.data);
        })
    }, [])
    return (
        <>
            <h3>Index generos</h3>
            <Link to="/generos/crear">Crear Genero</Link>
            <Link to="/generos/editar">Editar Genero</Link>
        </>
    )
}