
import FormularioGeneros from './FormularioGeneros';

export default function CrearGenero(){



    return (
        <>
            <h3>Crear Genero</h3>

            <FormularioGeneros 
                modelo={
                    //Valores por default de cada campo
                    {nombreGenero:'', 
                    in_edad:0,
                    nv_email:'', 
                    fechaNacimiento:undefined
                    }
                }
                onSubmit={async (valores, acciones) =>{
                    await new Promise(r =>setTimeout(r,3000))
                    console.log(valores);
                    //acciones.setSubmitting(false);
                }}
            />
        </>
    )
}