import './App.css';
import Menu from './utilerias/Menu';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import rutas from './utilerias/RutasConfig';
import validacionesPersonalizadasYup from './ValidacionesPersonalizadasYup';

validacionesPersonalizadasYup();

function App() {

///////////////////////////////////////////////////////
  return (
   <>
    <BrowserRouter>
    
      <Menu/>

      <div className="container">
        
      <Switch>
        {rutas.map(ruta => <Route key={ruta.path} path={ruta.path} exact={ruta.exact}><ruta.componente/></Route>)}
      </Switch>

      

      </div>
     </BrowserRouter>
   </>
      
  
  );
}

export default App;
