//import { useParams } from "react-router"
import FormularioGeneros from "./FormularioGeneros";

export default function EditarGenero(){

    //const {id}:any = useParams();

    return (
        <>
            <h3>Editar Genero</h3>

            <FormularioGeneros 
                modelo={
                    //Valores por default de cada campo
                    {nombreGenero:'Hello', 
                    in_edad:0,
                    nv_email:'', 
                    fechaNacimiento:undefined
                    }
                }
                onSubmit={async (valores, acciones) =>{
                    await new Promise(r =>setTimeout(r,3000))
                    console.log(valores);
                    //acciones.setSubmitting(true);
                }}
            />
        </>
    )
}