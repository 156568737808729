import { Form, Formik, FormikHelpers } from "formik";
import { Link } from "react-router-dom";
import Boton from '../utilerias/Boton';
import * as yup from 'yup'; 
import FormGroupTexto from '../utilerias/FormGroupTexto';
import { generoCreacionDTO } from "./generos.model";
import FormGroupFecha from '../utilerias/FormGroupFecha';
import FormGroupImagen from '../utilerias/FormGroupImagen';


export default function FormularioGeneros(props:formularioGenerosProps){

    return(
        <Formik
        initialValues={props.modelo}
        onSubmit={props.onSubmit}

        validationSchema={yup.object({
            //Validaciones
            nombreGenero: yup.string().required('Este campo es requerido').max(30,'No debe exceder de 30 caracteres').primeraLetraMayuscula(),
            in_edad: yup.number().required('Este campo es requerido').positive('Debe ser positivo').integer('Debe ser entero'),
            nv_email: yup.string().required('Este campo es requerido').email('Formato de correo inválido'),
            fechaNacimiento: yup.date().nullable().required('Este campo es requerido')
        })

        }
    >

        {(formikProps) => (
            <Form>
                <FormGroupTexto campo="nombreGenero" label="Genero" placeholder="Teclee el género"/>
                <FormGroupTexto campo="in_edad" label="Edad" placeholder="Edad de la persona"/>
                <FormGroupTexto campo="nv_email" label="Correo" placeholder="correo@dominio.com"/>
                <FormGroupFecha campo="fechaNacimiento" label="Fecha Nac" placeholder="Seleccione fecha"/>
                <FormGroupImagen campo="foto" label="Foto" imagenURL={props.modelo.fotoURL}/>

                <Boton className="btn btn-primary" disabled={formikProps.isSubmitting} type="submit">Grabar</Boton>
                <Link className="btn btn-secondary" to="/generos">Cancelar</Link>
                <Boton className="btn btn-danger mb-2" onClick={() => formikProps.setValues(props.modelo)}>Limpiar</Boton>

            </Form>
        )}



    </Formik>

    )

}


interface formularioGenerosProps{

    modelo: generoCreacionDTO;
    onSubmit(valores:generoCreacionDTO, accion:FormikHelpers<generoCreacionDTO>):void;

}