import { ErrorMessage, Field, useFormikContext } from "formik";
import MostrarErrorCampo from "./MostrarErrorCampo";

export default function FormGroupFecha(props: formGroupFechaProps){

    const {values, validateForm, touched, errors} = useFormikContext<any>();

    return(
        <div className="form-group">
        {props.label ? <label htmlFor={props.campo}>{props.label}</label> : null}

        <input type="date" 
            className="form-control" 
            name={props.campo} 
            id={props.campo} 
            defaultValue={values[props.campo]?.toLocaleDateString('en-CA')} 
            placeholder={props.placeholder}
            onChange={e => {
                const fecha = new Date(e.currentTarget.value + 'T00:00:00');
                values[props.campo]=fecha;
                validateForm();
            }}
        />
        {touched[props.campo] && errors[props.campo] ?
            <MostrarErrorCampo mensajeError={errors[props.campo]?.toString()!}/>:null
        }


        </div>
    )
}

interface formGroupFechaProps {
    campo:string;
    label?:string;
    placeholder?:string;

}