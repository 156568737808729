export default function MostrarErrorCampo(props: mostrarErrorCampoProps){

    return (
        <div className="text-danger">{props.mensajeError}</div>
    )

}

interface mostrarErrorCampoProps{
    mensajeError:string;
}