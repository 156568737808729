import { peliculaModel } from "./peliculas.model";
import css from './ListadoPeliculas.module.css';
import PeliculaIndividualComponente from './PeliculaIndividual';
import ListadoGenerico from '../utilerias/ListadoGenerico';


export default function ListadoPeliculas(props:listadoPeliculasProps){


        return(
            <ListadoGenerico 
            listado={props.modelo}>
                <div className={css.div}>
                    {props.modelo?.map(peliculaMap => <PeliculaIndividualComponente 
                    modelo={peliculaMap}
                    key={peliculaMap.id}
                    
                    />)}
                </div> 
            </ListadoGenerico>

         )
}

interface listadoPeliculasProps{
    modelo?: peliculaModel[];
}