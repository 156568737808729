import React, { useEffect, useState } from 'react';
import {landingPageDTO} from './peliculas/peliculas.model';
import ListadoPeliculasComponente from './peliculas/ListadoPeliculas';

export default function LandingPage(){

  /////////ESTADOS//////////////////////////////////////
  const [peliculasEstado, setPeliculasEstado]=useState<landingPageDTO>({})


  /////////////////////////////////////////////////////
  useEffect(() => {
    const timerId = setTimeout(() =>{

      setPeliculasEstado({
        enCartelera:
        [
          {
            id:1,
            titulo:'Spiderman',
            poster:'https://m.media-amazon.com/images/M/MV5BYTdkNzMwMDYtZjlmNi00MTk0LTgwNTktODEzMzYwMTU3MTc5XkEyXkFqcGdeQXVyNzg5MzIyOA@@._V1_QL75_UX280_CR0,41,280,414_.jpg'
        
          },
          {
            id:2,
            titulo:'Batman',
            poster:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ7nrh-cmo7JnXkmomRy8MIhhj5dMvMf8tOVA&usqp=CAU'
        
          },
          {
            id:3,
            titulo:'IronMan',
            poster:'https://phantom-expansion.unidadeditorial.es/aebe530e76c4e249f706003ea94b1978/resize/640/assets/multimedia/imagenes/2021/04/19/16188274367804.jpg'
        
          }
        ],
        proximosEstrenos:
        [
         {
            id:4,
            titulo:'La Isla',
            poster:'https://i1.wp.com/hipertextual.com/wp-content/uploads/2020/12/secuela-mision-de-rescate-extraction-netflix.jpg?fit=1200%2C675&ssl=1'
        
          },
          {
            id:5,
            titulo:'Hello World',
            poster:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS2zxF7eGw1-ufYRNPkx6_t9WtCdGy_AiGEQ&usqp=CAU'
        
          } 
        ]
        })

    },1000);

    return () => clearTimeout(timerId);
  })



    return(
        <>
            <h3>En Cartelera</h3>

          <ListadoPeliculasComponente modelo={peliculasEstado.enCartelera} />

          <h3>Próximos estrenos</h3>

          <ListadoPeliculasComponente modelo={peliculasEstado.proximosEstrenos} />
        </>

    )
}