import LandingPage from "../LandingPage";
import IndexGeneros from "../generos/indexGeneros";
import CrearGenero from "../generos/CrearGenero";
import EditarGenero from "../generos/EditarGenero";
import RedireccionarRuta from "../utilerias/RedireccionarRuta";

const rutas = [
    
    // En la primer ruta debe ir exact:true para que no se confunda con las demás que inician igual
    {path: '/generos', componente: IndexGeneros, exact: true},
    {path: '/generos/crear', componente: CrearGenero},
    {path: '/generos/editar/:id(\\d+)', componente: EditarGenero},
    
    //Home
    {path: '/', componente: LandingPage, exact: true},

    //Path que atrapa rutas no encontradas y las reedirige, tiene que ir al final siempre
    {path: '*', componente: RedireccionarRuta, exact: true}
];

export default rutas;
