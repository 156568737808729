
export default function Boton(props: buttonsProps){

    return (
        <button type={props.type} className={props.className} onClick={props.onClick}>{props.children}</button>

    )

}

interface buttonsProps{
    children:React.ReactNode,
    onClick?():void;
    type: "button" | "submit";
    disabled: boolean;
    className: string;
}

Boton.defaultProps = {
    type:"button",
    disabled:false,
    className: "btn btn-primary"
}
