import { ErrorMessage, Field } from "formik";
import MostrarErrorCampo from "./MostrarErrorCampo";

export default function FormGroupTexto(props: formGroupTextoProps){

    return(
        <div className="form-group">
        {props.label ? <label htmlFor={props.campo}>{props.label}</label> : null}
        <Field name={props.campo} className="form-control" placeholder={props.placeholder}/>
        <ErrorMessage name={props.campo}>
            {mensajeError => <MostrarErrorCampo mensajeError={mensajeError}/>}
        </ErrorMessage>
        </div>
    )
}

interface formGroupTextoProps {
    campo:string;
    label?:string;
    placeholder?:string;

}