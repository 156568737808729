import { peliculaModel } from "./peliculas.model";
import css from './PeliculaIndividual.module.css';


export default function PeliculaIndividual(props:peliculaIndividualProps){

    const construirLink = () => `/peliculas/${props.modelo.id}`

    return(
       <div className={css.div}>
           <a href="{construirLink()}">
               <img src={props.modelo.poster} alt="Poster"/>
           </a>
           <p>
               <a href="{construirLink()}">{props.modelo.titulo}</a>
           </p>
       </div> 
    )

}

interface peliculaIndividualProps{
    modelo: peliculaModel;
}