import * as yup from 'yup'

export default function ValidacionesPersonalizadasYup(){

    ////// Validación primer letra mayuscula
    yup.addMethod(yup.string, 'primeraLetraMayuscula', function(){

        return this.test('primera-letra-mayuscula', 'La primera letra debe ser mayúscula',
        function (valor) {
            if (valor && valor.length > 0){
                const primeraLetra = valor.substring(0,1);
                return primeraLetra === primeraLetra.toUpperCase();
            }
            return true;
        } )
        
    });


    ////////// Siguiente Validación aqui seguiría


}